/* You can add global styles to this file, and also import other style files */

#themecolors a {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/*tailwindcss*/
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
