
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
$font-family: 'Roboto';
$fontConfig: mat.define-typography-config(
  $headline-1: mat.define-typography-level(96px, 96px, 300, -1.5px, $font-family),
  $headline-2: mat.define-typography-level(60px, 60px, 300, -0.5px, $font-family),
  $headline-3: mat.define-typography-level(48px, 48px, 400, 0, $font-family),
  $headline-4: mat.define-typography-level(34px, 34px, 400, 0.25px, $font-family),
  $headline-5: mat.define-typography-level(24px, 24px, 400, 0, $font-family),
  $headline-6: mat.define-typography-level(20px, 20px, 500, 0.15px, $font-family),
  $subtitle-1: mat.define-typography-level(16px, 16px, 400, 0.15px, $font-family),
  $subtitle-2: mat.define-typography-level(14px, 14px, 500, 0.1px, $font-family),
  $body-1: mat.define-typography-level(16px, 24px, 400, 0.5px, $font-family),
  $body-2: mat.define-typography-level(14px, 20px, 400, 0.25px, $font-family),
  $caption: mat.define-typography-level(12px, 20px, 400, 0.4px, $font-family),
  $button: mat.define-typography-level(14px, 14px, 500, 1.25px, $font-family),
  $overline: mat.define-typography-level(10px, 10px, 400, 1.5px, $font-family)
);

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base:              black,
  divider:           $dark-dividers,
  dividers:          $dark-dividers,
  disabled:          $dark-disabled-text,
  disabled-button:   rgba($dark-text, 0.26),
  disabled-text:     $dark-disabled-text,
  elevation:         black,
  secondary-text:    $dark-accent-text,
  hint-text:         $dark-disabled-text,
  accent-text:       $dark-accent-text,
  icon:              $dark-accent-text,
  icons:             $dark-accent-text,
  text:              $dark-primary-text,
  slider-min:        $dark-primary-text,
  slider-off:        rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base:              $light-text,
  divider:           $light-dividers,
  dividers:          $light-dividers,
  disabled:          $light-disabled-text,
  disabled-button:   rgba($light-text, 0.3),
  disabled-text:     $light-disabled-text,
  elevation:         black,
  hint-text:         $light-disabled-text,
  secondary-text:    $light-accent-text,
  accent-text:       $light-accent-text,
  icon:              $light-text,
  icons:             $light-text,
  text:              $light-text,
  slider-min:        $light-text,
  slider-off:        rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background:    #fafafa;
$light-bg-darker-5:   darken($light-background, 5%);
$light-bg-darker-10:  darken($light-background, 10%);
$light-bg-darker-20:  darken($light-background, 20%);
$light-bg-darker-30:  darken($light-background, 30%);
$light-bg-lighter-5:  lighten($light-background, 5%);
$dark-bg-tooltip:     lighten(#2c2c2c, 20%);
$dark-bg-alpha-4:     rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12:    rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background:               $light-background,
  status-bar:               $light-bg-darker-20,
  app-bar:                  $light-bg-darker-5,
  hover:                    $dark-bg-alpha-4,
  card:                     $light-bg-lighter-5,
  dialog:                   $light-bg-lighter-5,
  tooltip:                  $dark-bg-tooltip,
  disabled-button:          $dark-bg-alpha-12,
  raised-button:            $light-bg-lighter-5,
  focused-button:           $dark-focused,
  selected-button:          $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle:   $light-bg-darker-10,
  unselected-chip:          $light-bg-darker-10,
  disabled-list-option:     $light-bg-darker-10,
);

// Dark bg
$dark-background:     #2c2c2c;
$dark-bg-lighter-5:   lighten($dark-background, 5%);
$dark-bg-lighter-10:  lighten($dark-background, 10%);
$dark-bg-lighter-20:  lighten($dark-background, 20%);
$dark-bg-lighter-30:  lighten($dark-background, 30%);
$light-bg-alpha-4:    rgba(#fafafa, 0.04);
$light-bg-alpha-12:   rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background:               $dark-background,
  status-bar:               $dark-bg-lighter-20,
  app-bar:                  $dark-bg-lighter-5,
  hover:                    $light-bg-alpha-4,
  card:                     $dark-bg-lighter-5,
  dialog:                   $dark-bg-lighter-5,
  tooltip:                  $dark-bg-lighter-20,
  disabled-button:          $light-bg-alpha-12,
  raised-button:            $dark-bg-lighter-5,
  focused-button:           $light-focused,
  selected-button:          $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle:   $dark-bg-lighter-10,
  unselected-chip:          $dark-bg-lighter-20,
  disabled-list-option:     $dark-bg-lighter-10,
);


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
// Theme Config

body {
  --primary-color: #fdd835;
  --primary-lighter-color: #fef3c2;
  --primary-darker-color: #fcc822;
  --text-primary-color: #{$dark-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$dark-primary-text};
}
$mat-primary: (
  main: #fdd835,
  lighter: #fef3c2,
  darker: #fcc822,
  200: #fdd835, // For slide toggle,
  contrast : (
    main: $dark-primary-text,
    lighter: $dark-primary-text,
    darker: $dark-primary-text,
  )
);
$surcanal-primary: mat.define-palette($mat-primary, main, lighter, darker);


body {
  --accent-color: #736627;
  --accent-lighter-color: #d5d1be;
  --accent-darker-color: #564917;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}
$mat-accent: (
  main: #736627,
  lighter: #d5d1be,
  darker: #564917,
  200: #736627, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$surcanal-accent: mat.define-palette($mat-accent, main, lighter, darker);


body {
  --warn-color: #ca0808;
  --warn-lighter-color: #efb5b5;
  --warn-darker-color: #b70404;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
  scroll-behavior: smooth;
}

$mat-warn: (
  main: #ca0808,
  lighter: #efb5b5,
  darker: #b70404,
  200: #ca0808, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$surcanal-warn: mat.define-palette($mat-warn, main, lighter, darker);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$surcanal-theme: mat.define-light-theme((
  color: (
    primary: $surcanal-primary,
    accent: $surcanal-accent,
    warn: $surcanal-warn,
  ),
  typography: $fontConfig
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($surcanal-theme);

.mdc-form-field>label{
  margin-bottom: 0;
}

